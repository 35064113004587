<template>
    <div>
        
        <!-- Modal - roles -->
        <my-modal id="set-roles" title="Set Roles" @ok="updateUserRoles">
            <my-drag-and-drop available-label="Available Roles" selected-label="Selected Roles" :available="response.roles.available"  v-model="response.roles.selected"></my-drag-and-drop>
        </my-modal>

        <!-- Modal - image -->
        <my-modal id="set-image" title="Set Image" @ok="updateUserImage">
            <b-form-file placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." v-model="user.image"></b-form-file>
        </my-modal>

        <div v-if="response.users[0] != null">
            
            <!-- Title -->
            <br>    
            <h3 class="text-dark">{{ (response.users[0].firstname ? response.users[0].firstname : 'Unknown User')  + ' ' + (response.users[0].lastname ? response.users[0].lastname : '') }}</h3>
            <br>
            
            <!-- Main -->
            <my-box>
                <b-row>
                    <b-col cols="auto">
                        <div class="d-flex align-items-end">
                            <my-image-input :src="response.users[0].big_image == null || response.users[0].big_image == '' ? 'https://moonvillageassociation.org/wp-content/uploads/2018/06/default-profile-picture1.jpg' : response.users[0].big_image" @remove="removeImage" @upload="$bvModal.show('set-image')"></my-image-input>
                        </div>
                    </b-col>
                    <b-col></b-col>
                    <b-col cols="auto">
                        <div class="d-flex flex-column h-100 justify-content-between">
                            <div>
                                <table>
                                    <tr class="border-bottom">
                                        <td style="vertical-align: bottom;">
                                            <span class="text-line-l" :style="!isActive ? 'color: #d9534f;' : ''">
                                                {{ response.users[0].active || 'NaN' }}
                                            </span>
                                        </td>
                                        <td style="vertical-align: bottom;">
                                            <span class="text-line-r">
                                                <button @click="toggleActive">{{ isActive ? 'Deactivate' : 'Activate' }}</button>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td style="vertical-align: bottom;">
                                            <span class="text-line-l" :style="!isVisible ? 'color: #d9534f;' : ''">
                                                {{ response.users[0].visible || 'NaN' }}
                                            </span>
                                        </td>
                                        <td style="vertical-align: bottom;">
                                            <span class="text-line-r">
                                                <button @click="toggleVisible">{{ isVisible ? 'Hide' : 'Show'}}</button>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td style="vertical-align: bottom;">
                                            <span class="text-line-l">
                                                {{ response.roles.selected.length > 0 ? response.roles.selected.map(x => x.name).join(', ') : '' }}
                                            </span>
                                        </td>
                                        <td style="vertical-align: bottom;">
                                            <span class="text-line-r">
                                                <button v-b-modal.set-roles>{{'Add or Remove Roles'}}</button>
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="text-right">
                                <b-button size="sm" variant="danger"  @click="deleteUser" class="ml-2" squared>Delete User</b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </my-box>
            
            <br>

            <!-- Details (editable) -->
            <my-box>
                <p class="title">Details</p>
                <my-table header-width="200" stacked borderless small :items="response.users" :fields="table.details.fields">
                    <template v-slot:cell(firstname)="data">
                        <my-auto-input :value="data.item.firstname" @change="updateUser" :options="{ name: 'firstname' }"></my-auto-input>
                    </template>
                    <template v-slot:cell(lastname)="data">
                        <my-auto-input :value="data.item.lastname" @change="updateUser" :options="{ name: 'lastname' }"></my-auto-input>
                    </template>
                    <template v-slot:cell(date_of_birth)="data">
                        <my-auto-input :value="data.item.date_of_birth" @change="updateUser" :options="{ name: 'date_of_birth' }"></my-auto-input>
                    </template>
                    <template v-slot:cell(work_phone)="data">
                        <my-auto-input :value="data.item.work_phone" @change="updateUser" :options="{ name: 'work_phone' }"></my-auto-input>
                    </template>
                    <template v-slot:cell(private_phone)="data">
                        <my-auto-input :value="data.item.private_phone" @change="updateUser" :options="{ name: 'private_phone' }"></my-auto-input>
                    </template>
                    <template v-slot:cell(work_email)="data">
                        <my-auto-input :value="data.item.work_email" @change="updateUser" :options="{ name: 'work_email' }"></my-auto-input>
                    </template>
                    <template v-slot:cell(private_email)="data">
                        <my-auto-input :value="data.item.private_email" @change="updateUser" :options="{ name: 'private_email' }"></my-auto-input>
                    </template>
                </my-table>
            </my-box>

            <br>

            <!-- Identifiers -->
            <my-box>
                <p class="title">Identifiers</p>
                <my-table header-width="200" stacked borderless small :items="response.users" :fields="table.identifiers.fields">
                    <template v-slot:cell(id)="data">
                        <my-auto-input :value="data.item.id" locked></my-auto-input>
                    </template>
                    <template v-slot:cell(guid)="data">
                        <my-auto-input :value="data.item.guid" locked></my-auto-input>
                    </template>
                </my-table>
            </my-box>

            <br>

            <!-- Metadata -->
            <my-box>
                <p class="title">Meta</p>
                <my-table header-width="200" stacked borderless small :items="response.users" :fields="table.meta.fields">
                    <template v-slot:cell(create_datetime)="data">
                        <my-auto-input :value="data.item.create_datetime" locked></my-auto-input>
                    </template>
                    <template v-slot:cell(update_datetime)="data">
                        <my-auto-input :value="data.item.update_datetime" locked></my-auto-input>
                    </template>
                </my-table>
            </my-box>

            <br>

            <!-- Credentials -->
            <my-box>
                <p class="title">Credentials</p>
                <my-table header-width="200" stacked borderless small :items="response.credentials" :fields="table.credentials.fields">
                    <template v-slot:cell(username)="data">
                        <my-auto-input :value="data.item.username" locked></my-auto-input>
                    </template>
                    <template v-slot:cell(password)="data">
                        <my-auto-input :value="data.item.password" locked></my-auto-input>
                    </template>
                </my-table>
            </my-box>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorMsg: null,
            hasLoaded: false,
            response: {
                users: [],
                roles: {
                    selected: [],
                    available: []
                },
                credentials: []
            },
            user: {
                image: null
            },
            table: {
                details: {
                    fields: [
                        {
                            key: 'firstname'
                        },
                        {
                            key: 'lastname'
                        },
                        {
                            key: 'date_of_birth'
                        },
                        {
                            key: 'work_phone'
                        },
                        {
                            key: 'private_phone'
                        },
                        {
                            key: 'work_email'
                        },
                        {
                            key: 'private_email'
                        },
                    ]
                },
                identifiers: {
                    fields: [
                        {
                            key: 'id'
                        },
                        {
                            key: 'guid'
                        }
                    ]
                },
                meta: {
                    fields: [
                        {
                            key: 'create_datetime'
                        },
                        {
                            key: 'update_datetime'
                        }
                    ]
                },
                credentials: {
                    fields: [
                        {
                            key: 'username'
                        },
                        {
                            key: 'password'
                        }
                    ]
                }
            }
        }
    },


    computed: {
        isActive() { return this.response.users[0].active == 'Active' },
        isVisible() { return this.response.users[0].visible == 'Visible' }
    },


    methods: {  
        
        toggleActive() {
            let oldValue = this.response.users[0].active
            let newValue = (oldValue == 'Active' || oldValue == null) ? 'Deactivated' : 'Active'
            this.$bvModal.msgBoxConfirm('Do you really wish to ' + (newValue == 'Active' ? 'activate' : 'deactivate') + ' this user?', { 
                title: 'Please Confirm', 
                size: 'md', 
                buttonSize: 'sm rounded-0', 
                okTitle: 'Confirm', 
                cancelTitle: 'Cancle', 
                contentClass: 'rounded-0 border-0', 
                headerClass: 'border-0', 
                footerClass: 'border-0', 
                hideHeaderClose: false
            }).then(confirmed => {
                if(confirmed) {
                    this.updateUser({ newValue, oldValue, options: { name: 'active' } })
                }
            })
        },

        toggleVisible() {
            let oldValue = this.response.users[0].visible
            let newValue = (oldValue == 'Visible' || oldValue == null) ? 'Hidden' : 'Visible'
            this.$bvModal.msgBoxConfirm('Do you really wish to ' + (newValue == 'Visible' ? 'show' : 'hide' ) + ' this user?', { 
                title: 'Please Confirm', 
                size: 'md', 
                buttonSize: 'sm rounded-0', 
                okTitle: 'Confirm', 
                cancelTitle: 'Cancle', 
                contentClass: 'rounded-0 border-0', 
                headerClass: 'border-0', 
                footerClass: 'border-0', 
                hideHeaderClose: false
            }).then(confirmed => {
                if(confirmed) {
                    this.updateUser({ newValue, oldValue, options: { name: 'visible' } })
                }
            })
        },

        // ----------------------------------------
        // --- Functionalities
        // ----------------------------------------

        /**
         * Remove user image
         */
        removeImage() {
            this.$bvModal.msgBoxConfirm('Do you really wish to remove this image?', { 
                title: 'Please Confirm', 
                size: 'md', 
                buttonSize: 'sm rounded-0', 
                okTitle: 'Confirm', 
                cancelTitle: 'Cancle', 
                contentClass: 'rounded-0 border-0', 
                headerClass: 'border-0', 
                footerClass: 'border-0', 
                hideHeaderClose: false
            }).then(confirmed => {
                if(confirmed) {
                    this.$store.dispatch("credentials/user/removeImage", { data: { filter: { id: this.$route.params.id }}})
                    .then(res => {
                        this.selectUser()
                    })
                    .catch(err => {
                        this.errorMsg = err.status + ' - ' + err.statusText                
                    })
                }
            })
        },

        /**
         * Restores a password for current user
         */
        restorePassword() {
            this.$bvModal.msgBoxConfirm('Do you really wish to restore the password for this user?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm rounded-0',
                okTitle: 'Confirm',
                cancelTitle: 'Cancle',
                contentClass: 'rounded-0 border-0',
                headerClass: 'border-0',
                footerClass: 'border-0',
                hideHeaderClose: false
            })
        },
 
        /**
         * Delete user permanently
         */
        deleteUser() {
            this.$bvModal.msgBoxConfirm('Do you really wish to delete this user?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm rounded-0',
                okTitle: 'Confirm',
                cancelTitle: 'Cancle',
                contentClass: 'rounded-0 border-0',
                headerClass: 'border-0',
                footerClass: 'border-0',
                hideHeaderClose: false
            }).then(confirmed => {
                if(confirmed) {
                    this.$store.dispatch('credentials/user/delete', { params: { id: this.$route.params.id }})
                    .then(res => {
                        this.$router.push({ path: '/credentials/users' })
                    })
                    .catch(err => {
                        this.errorMsg = err.status + ' - ' + err.statusText
                    })
                }
            })


        },

        /**
         * Update user data
         */
        updateUser({ newValue, oldValue, options }) {
            this.$store.dispatch("credentials/user/put", { data: { filter: { id: this.$route.params.id }, data: { [options.name]: newValue }}})
            .then(res => {
                this.selectUser()
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText                
            })
        },

        /**
         * Update user roles
         */
        updateUserRoles() {
            this.$store.dispatch('credentials/user/setRoles', { data: { filter: { id: this.$route.params.id }, data: { roles: this.response.roles.selected.map(x => x.id) }}})
            .then(res => {
                this.selectUser()
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText
            })
        },

        /**
         * Update user image
         */
        updateUserImage() {
            this.$store.dispatch('credentials/user/setImage', { data: { id: this.$route.params.id, image: this.user.image}})
            .then(res => {
                this.selectUser()
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText
            })
        },

        /**
         * Fetch user data
         */
        selectUser() {
            this.$store.dispatch("credentials/user/get", { params: { id: this.$route.params.id }} )
            .then(res => {
                this.response.users = res.data.route
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText
            })

            this.$store.dispatch("credentials/user/roles/get", { params: { user_id: this.$route.params.id }})            
            .then(res => {
                this.response.roles.selected = res.data.route.map(x => x.role)
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText
            })

            this.$store.dispatch("credentials/credentials/get", { params: { user_id: this.$route.params.id }})            
            .then(res => {
                this.response.credentials = res.data.route
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText
            })
        },

        /**
         * Fetch all available roles
         */
        selectRoles() {
            this.$store.dispatch('credentials/role/get', { data: { id: this.$route.params.id }})
            .then(res => {
                this.response.roles.available = res.data.route
            })
            .catch(err => {
                this.errorMsg = err.status + ' - ' + err.statusText
            })
        }
    },

    created() {
        this.selectUser()
        this.selectRoles()
    },
};

</script>

<style>
.text-line-l {
    text-align: start;
    font-size: 1em;
    width: 260px;
    max-width: 260px;
    padding: 10px 12px 2px 12px;
    display: block;
}
.text-line-r {
    text-align: end;
    font-size: 1em;
    width: 260px;
    max-width: 260px;
    padding: 10px 12px 2px 12px;
    display: block;
}
.text-line-r button, .text-line-l button {
    border: 0;
    background: 0;
    color: #0275d8;
    outline: 0;
    padding: 0;
}
.text-line-r button:hover, .text-line-l button:hover {
    text-decoration: underline;
    color: #005fb3;
}
.title {
    font-weight: 500;
}

/* Stacked table with custom header width */
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    max-width: 200px;
    font-weight: normal;
    color: gray;
}
.table.b-table.b-table-stacked > tbody > tr {
    font-size: 0.9rem;
    width: auto;
}
</style>